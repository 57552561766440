import React, { useContext, useState } from 'react';
import ReactFlagsSelect, { Us } from "react-flags-select";

import { languageOptions } from 'languages';
import { LanguageContext } from 'providers/LanguageProvider';

export default function LanguageSelector() {
    const { userLanguageChange } = useContext(LanguageContext);
    const [selected, setSelected] = useState("");

    // set selected language by calling context method
    const handleLanguageChange = e => {
        userLanguageChange(e.toLowerCase());
        setSelected(e);
    }

    return (
        <ReactFlagsSelect
            selected={selected}
            onSelect={handleLanguageChange}
            countries={["US", "FR", "DE", "IT", "ES"]}
            customLabels={{
                US: languageOptions["en"],
                FR: languageOptions["fr"],
                DE: languageOptions["de"],
                IT: languageOptions["it"],
                ES: languageOptions["es"],
            }}
            showSelectedLabel={false}
            showSecondarySelectedLabel={false}
            fullWidth={false}
            alignOptionsToRight={true}
            placeholder={<Us />}
            optionsSize={20}
            selectedSize={20}
        />
    );
};