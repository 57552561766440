import React, { useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ContentWithPaddingXl, Container as ContainerBase } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/decorators/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/decorators/svg-decorator-blob-8.svg";
import { LanguageContext } from 'providers/LanguageProvider';

const Heading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center text-primary-600 lg:mx-24`;
const Container = tw(ContainerBase)`text-gray-100`
const ThreeColumnsContainer = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const ColumnContainer = tw.div`mt-16 lg:w-1/3`;
const Column = tw.div`px-4 max-w-xs mx-auto flex flex-col justify-start`;
const Image = tw.img`w-80 h-80`;
const Title = tw.h4`mb-3 text-xl font-bold text-gray-900`;
const Description = tw.div``;
const TextLine = tw.p`mt-2 font-normal text-xl text-gray-700 max-w-xl`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
    ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
    ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default () => {
    const { dictionary } = useContext(LanguageContext);

    const heading = dictionary["threeColumnFeaturesTitle"];

    const columns = [
        {
            key: 0,
            imageSrc: require("images/product-images/image7.jpg"),
            title: dictionary["columnOneFeatureTitle"],
            description: dictionary["columnOneFeatureDescription"]
        },
        {
            key: 1,
            imageSrc: require("images/product-images/image6.jpg"),
            title: dictionary["columnTwoFeatureTitle"],
            description: dictionary["columnTwoFeatureDescription"]
        },
        {
            key: 2,
            imageSrc: require("images/product-images/image5.jpg"),
            title: dictionary["columnThreeFeatureTitle"],
            description: dictionary["columnThreeFeatureDescription"]
        }
    ];

    return (
        <Container>
            <ContentWithPaddingXl>
                <Heading>{heading}</Heading>
                <ThreeColumnsContainer>
                    {columns.map((column, index) => (
                        <ColumnContainer key={index}>
                            <Column>
                                <Image src={column.imageSrc} />
                                <Title>{column.title}</Title>
                                <Description>{column.description.split('\n').map((str, idx) => <TextLine key={idx}>{str}</TextLine>)}</Description>
                            </Column>
                        </ColumnContainer>
                    ))}
                </ThreeColumnsContainer>
            </ContentWithPaddingXl>

            <DecoratorBlob1 />
            <DecoratorBlob2 />
        </Container>
    );
};
