import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import LanguageSelector from "components/misc/LanguageSelector.js";
import heroBackgroundImage from "images/product-images/image9.jpg"
import logo from "images/logo/logo.svg";

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url(${heroBackgroundImage});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-gray-700 opacity-75`;
const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-56 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Header = tw.header`flex flex-row justify-between items-center max-w-screen-xl mx-auto lg:pt-8 px-2 max-w-none`;
const LanguageSelectorContainer = tw.div`mt-2`;
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-3 text-2xl text-gray-100 tracking-wider`;

export default () => {
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <Header>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>Woodtastic</LogoText>
          </LogoContainer>
          <LanguageSelectorContainer>
            <LanguageSelector />
          </LanguageSelectorContainer>
        </Header>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              <span>WOODTASTIC</span>
              <br />
              <SlantedBackground>Rummy Game</SlantedBackground>
            </Heading>
          </LeftColumn>
          <RightColumn />
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
