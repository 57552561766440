import React from "react";
import tw from "twin.macro";
import Hero from "./components/main/Hero.js";
import AlternatingFeatures from "./components/main/AlternatingFeatures.js";
import ThreeColumnFeatures from "./components/main/ThreeColumnFeatures.js";
import Contact from "./components/main/Contact.js";
import Footer from "./components/main/Footer.js";
import { LanguageProvider } from "providers/LanguageProvider.js";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

export default () => (
    <LanguageProvider>
        <StyledDiv className="App">
            <Hero />
            <AlternatingFeatures />
            <ThreeColumnFeatures />
            <Contact />
            <Footer />
        </StyledDiv>
    </LanguageProvider>
);
