import React, { useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "images/icons/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "components/misc/Headings.js";
import { Container } from "components/misc/Layouts";
import { TranslateText, LanguageContext } from 'providers/LanguageProvider';

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-12`; 

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-normal text-xl text-center max-w-xl`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/2 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-16`;
const Title = tw.h4`mb-3 text-2xl font-bold text-gray-900`;
const Description = tw.div``;
const TextLine = tw.p`mt-2 font-normal text-xl text-gray-700 max-w-xl`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
// const SvgDotPattern2 = tw(
//     SvgDotPatternIcon
// )`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
    const { dictionary } = useContext(LanguageContext);

    const cards = [
        {
            key: 0,
            imageSrc: require("images/product-images/image1.jpg"),
            title: dictionary["alternatingFeatureOneTitle"],
            description: dictionary["alternatingFeatureOneDescription"],
        },
        {
            key: 1,
            imageSrc: require("images/product-images/image2.jpg"),
            title: dictionary["alternatingFeatureTwoTitle"],
            description: dictionary["alternatingFeatureTwoDescription"],
        },
        {
            key: 2,
            imageSrc: require("images/product-images/image3.jpg"),
            title: dictionary["alternatingFeatureThreeTitle"],
            description: dictionary["alternatingFeatureThreeDescription"],
        }
    ];

    const alternatingFeaturesDescription = dictionary["alternatingFeaturesDescription"];

    return (
        <Container>
            <SingleColumn>
                <HeadingInfoContainer>
                    <HeadingTitle>
                        <TranslateText tid="alternatingFeaturesTitle" />
                    </HeadingTitle>
                    <HeadingDescription>
                        {alternatingFeaturesDescription.split('\n').map((str, idx) => <TextLine key={idx}>{str}</TextLine>)}
                    </HeadingDescription>
                </HeadingInfoContainer>

                <Content>
                    {cards.map((card, i) => (
                    <Card key={i} reversed={i % 2 === 1}>
                        <Image imageSrc={card.imageSrc} />
                        <Details>
                            <Title>{card.title}</Title>
                            <Description>{card.description.split('\n').map((str, idx) => <TextLine key={idx}>{str}</TextLine>)}</Description>
                        </Details>
                    </Card>
                    ))}
                </Content>
            </SingleColumn>

            <SvgDotPattern1 />
            <SvgDotPattern3 />
            <SvgDotPattern4 />
        </Container>
    );
};
